import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Splash from "./pages/Splash";
import Home from "./pages/Home";
import About from "./pages/About";
import CampaignUpdates from "./pages/CampaignUpdates";
import Donors from "./pages/Donors";
import DonorVoices from "./pages/DonorVoices";
import DonateNow from "./pages/DonateNow";
import Contact from "./pages/Contact";
import Footer from "./components/footer";
import { ScrollToTop } from "./components/scrollToTop";
import AllPosts from "./cms/AllPosts";
import SinglePost from "./cms/SinglePost";

const App = () => {
  // full site
  return (
    <Router>
      <ScrollToTop />
      <div className="hoban-capital-campaign">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/campaign-updates" element={<CampaignUpdates />} />
          {/* <Route path="/donors" element={<Donors />} /> */}
          <Route path="/donor-voices" element={<DonorVoices />} />
          <Route path="/donate" element={<DonateNow />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/splash" element={<Splash />} /> */}
          <Route path="/allPosts" element={<AllPosts />} />
          <Route path="/post/:id" element={<SinglePost />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );

  // splash site
  // return (
  //   <>
  //     <div className="hoban-capital-campaign">
  //       <div className="main-container">
  //         <Splash />
  //       </div>
  //     </div>
  //     <Footer />
  //   </>
  // );
};

export default App;
