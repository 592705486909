import { Link } from "react-router-dom";
import campaignTextLogo from "../images/campagin-text-logo.png";
import topSectionBackground from "../images/footer-background-dome.png";
import { Column, Columns } from "./columns";
import Subscribe from "./subscribe";
import topBg from "../images/footer/footer-bg.png";

const Footer = () => {
  let isDesktop = window.innerWidth >= 1024;

  return (
    <div className="footer">
      <div
        className="top-section"
        style={
          // isDesktop ? { backgroundImage: `url(${topSectionBackground})` } : {}
          isDesktop ? { backgroundImage: `url(${topBg})` } : {}
        }
      >
        <Columns className="top-content-container">
          <Column className="donate-container">
            <p>
              Every Knight, Every Day, the most ambitious capital campaign in
              Hoban history, offers an intimate portrait of Hoban’s future – a
              vision that will guide our school and community confidently in the
              21st century.{" "}
            </p>
            <Link to="/donate" className="donate-button">
              <span>Donate</span>
              <span>Now</span>
            </Link>
          </Column>
          <Column className="links-container">
            <div className="site-links">
              <Link to="/" className="link">
                <span>Home</span>
              </Link>
              <Link to="/about" className="link">
                <span>About the Campaign</span>
              </Link>
              <Link to="/campaign-updates" className="link">
                <span>Campaign Updates</span>
              </Link>
              {/* <Link to="/donors" className="link">
                <span>Donors</span>
              </Link> */}
              <Link to="/donor-voices" className="link">
                <span>Donor Voices</span>
              </Link>
              <Link to="/donate" className="link">
                <span>Giving</span>
              </Link>
              <Link to="/contact" className="link">
                <span>Contact</span>
              </Link>
            </div>
            <div className="social-links">
              <a
                href="https://www.instagram.com/hobanknights"
                className="link"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>Instagram</span>
              </a>
              <a
                href="https://www.facebook.com/HobanKnights"
                className="link"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>Facebook</span>
              </a>
              <a
                href="https://twitter.com/hoban"
                className="link"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>Twitter</span>
              </a>
              <a
                href="https://www.youtube.com/user/archbishophoban"
                className="link"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>YouTube</span>
              </a>
            </div>
          </Column>
        </Columns>
        <div className="subscribe-padding">
          <div className="subscribe-container">
            <span className="subscribe-message">
              <b>Stay up to date</b> on the Campaign’s status.
            </span>
            <Subscribe />
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-content-container">
          <div className="school-info-container">
            <img className="every-knight-logo" src={campaignTextLogo} />
            {/* <p>
              Archbishop Hoban High School is a Roman Catholic High School based
              in the Holy Cross Tradition. Located in Akron, Ohio, Hoban
              services info info info. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Maecenas ac vulputate lacus, congue tempus
              ligula.
            </p> */}
            <p>
              <b>Archbishop Hoban High School</b>
              <br />
              One Holy Cross Boulevard{isDesktop ? <br /> : <>, </>}Akron, OH
              44306
            </p>
            <p>Copyright 2022, All Rights Reserved</p>
          </div>
          {/* <div className="wordmark-container">
            <img src={campaignTextLogo} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
