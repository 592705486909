import React, { useState } from "react";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import { Column, Columns } from "../components/columns";
//@ts-ignore
import Fade from "react-reveal/Fade";
import donateHeroBg from "../images/donate/donate_hero_bg.jpg";
import donateMessageBg from "../images/donate/donate_message_bg.jpg";
import donateGivingLevelsBg from "../images/donate/donate_giving_levels_bg.jpg";
import givingLevelsShield from "../images/donate/giving_levels_shield.png";
import donateHowToGiveBg from "../images/donate/how_to_give_bg.jpg";
import donateBg from "../images/about/donate_bg.jpg";
import pledgeBg from "../images/about/pledge_bg.jpg";
import rightArrow from "../images/icons/right-arrow.svg";

const DonateNow = () => {
  let isDesktop = window.innerWidth >= 1024;
  const [showCash, toggleShowCash] = useState(false);
  const [showSecurities, toggleShowSecurities] = useState(false);
  const [showIra, toggleShowIra] = useState(false);
  const [showLife, toggleShowLife] = useState(false);
  const [showReal, toggleShowReal] = useState(false);
  const [showRetained, toggleShowRetained] = useState(false);
  const [showCharitable, toggleShowCharitable] = useState(false);
  const [showBequest, toggleShowBequest] = useState(false);
  const [showRetirement, toggleShowRetirement] = useState(false);
  const [showCrypto, toggleShowCrypto] = useState(false);

  return (
    <div className="container donate">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="donate-section_intro">
        <div
          className="hero-bg"
          style={isDesktop ? { backgroundImage: `url(${donateHeroBg})` } : {}}
        >
          {isDesktop && <Header logoColor="blue" />}
          <Fade bottom>
            <h1 className="heading">Donate Now.</h1>
          </Fade>
          <Fade bottom>
            <p className="intro-blurb">
              Every Knight, Every Day is a transformative endeavor, and our
              success is dependent on your support. Your gift will make a
              powerful and lasting impact on the experience of every Hoban
              student, now and in the future.{" "}
              <b>Can we count on you to support Every Knight, Every Day?</b>
            </p>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="donate-section_giving-levels">
        <div
          className="giving-levels-bg"
          style={
            isDesktop ? { backgroundImage: `url(${donateGivingLevelsBg})` } : {}
          }
        >
          <Fade bottom>
            <h3>Giving Levels</h3>
          </Fade>
          <div className="levels-columns">
            <div className="left">
              <Fade bottom>
                <img src={givingLevelsShield} />
              </Fade>
            </div>
            <Fade bottom>
              <div className="right">
                <p className="title">
                  Contact us for more information about naming and recognition.
                </p>
                <p className="sub">
                  No gift is too small to make an impact and help us realize our
                  goal for Every Knight, Every Day.
                </p>
                <div className="contact">
                  <div className="top">
                    <div>
                      <span className="name">Chris DiMauro ’99</span>
                      <br />
                      <a href="mailto:dimauroc@hoban.org">
                        <span className="email">dimauroc@hoban.org</span>
                      </a>
                      <br />
                      <a href="tel:330.849.2150">
                        <span className="phone">330.849.2150</span>
                      </a>
                    </div>
                    <div>
                      <span className="name">
                        Cathy
                        <br />
                        (Keller ’80) Reeves
                      </span>
                      <br />
                      <a href="mailto:reevesc@hoban.org">
                        <span className="email">reevesc@hoban.org</span>
                      </a>
                      <br />
                      <a href="tel:330.773.8620">
                        <span className="phone">330.773.8620</span>
                      </a>
                    </div>
                    <p className="phone"></p>
                  </div>
                  <div className="bottom">
                    <div>
                      <span className="name">
                        Laura Jo
                        <br />
                        (Marcinkoski ’83)
                        <br />
                        Hawk
                      </span>
                      <br />
                      <a href="mailto:hawklj@hoban.org">
                        <span className="email">hawklj@hoban.org</span>
                      </a>
                      <br />
                      <a href="tel:330.773.8620">
                        <span className="phone">330.773.8620</span>
                      </a>
                    </div>
                    <div>
                      <span className="name">Tim Tyrrell</span>
                      <br />
                      <a href="mailto:tyrrellt@hoban.org">
                        <span className="email">tyrrellt@hoban.org</span>
                      </a>
                      <br />
                      <a href="tel:330.773.8620">
                        <span className="phone">330.773.8620</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </PageSection>
      <PageSection className="donate-section_donate">
        <div className="donate-links">
          <div
            className="donate-links-content"
            style={{ backgroundImage: `url(${pledgeBg})` }}
          >
            <div className="content left">
              <p>Make your Pledge.</p>
              <Fade bottom>
                <a
                  href="https://www.hoban.org/campaign-pledge-form"
                  className="cta-button"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span>Pledge Over Time</span>
                  <img src={rightArrow} />
                </a>
              </Fade>
            </div>
          </div>
          <div
            className="donate-links-content"
            style={{ backgroundImage: `url(${donateBg})` }}
          >
            <div className="content right">
              <p>Donate online.</p>
              <Fade bottom>
                <a
                  href="https://www.hoban.org/campaign-giving-form"
                  className="cta-button"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span>Donate Now</span>
                  <img src={rightArrow} />
                </a>
              </Fade>
            </div>
          </div>
        </div>
      </PageSection>
      <PageSection className="donate-section_how-to-give">
        <div
          className="how-to-give-bg"
          style={
            isDesktop ? { backgroundImage: `url(${donateHowToGiveBg})` } : {}
          }
        >
          <Columns className="how-columns">
            <Column className="title">
              <Fade bottom>
                <p>How To Give</p>
              </Fade>
            </Column>
            <Column className="giving-options">
              <Fade bottom>
                <div className="options">
                  <div className="cash-group">
                    <div
                      className="cash"
                      onClick={() => toggleShowCash(!showCash)}
                    >
                      <p>Cash</p>
                    </div>
                    <div
                      className={`cash-description ${
                        showCash ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Outright gifts of cash, credit card or check are the
                        easiest way to make a gift to Hoban.
                      </p>
                    </div>
                  </div>
                  <div className="securities-group">
                    <div
                      className="securities"
                      onClick={() => toggleShowSecurities(!showSecurities)}
                    >
                      <p>Securities</p>
                    </div>
                    <div
                      className={`securities-description ${
                        showSecurities ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Long-term appreciated stocks and other securities may be
                        transferred to Hoban. The donor may avoid capital gains
                        taxes upon sale of the stock and be able to claim a
                        charitable deduction.
                        <br />
                        <br />
                        Stock Tool:&nbsp;
                        <a
                          href="http://www.freewill.com/stocks/hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          www.freewill.com/stocks/hoban
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="ira-group">
                    <div
                      className="ira"
                      onClick={() => toggleShowIra(!showIra)}
                    >
                      <p>IRA Charitable Rollover</p>
                    </div>
                    <div
                      className={`ira-description ${
                        showIra ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Individuals age 70½ and older can donate up to $100,000
                        from their IRAs to Hoban tax-free. Donors do not receive
                        an income tax charitable deduction. This provision
                        allows donors to transfer money from their IRAs directly
                        to Hoban without having to recognize the transfer as
                        taxable income.
                        <br />
                        <br />
                        QCD Tool:&nbsp;
                        <a
                          href="http://www.freewill.com/qcd/hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          www.freewill.com/qcd/hoban
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="life-group">
                    <div
                      className="life"
                      onClick={() => toggleShowLife(!showLife)}
                    >
                      <p>Life Insurance</p>
                    </div>
                    <div
                      className={`life-description ${
                        showLife ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        An individual may contribute a paid-up life insurance
                        policy, buy a new policy for Hoban, or contribute an
                        existing policy. In addition, a donor may add Hoban as a
                        partial beneficiary to an existing policy. The donor may
                        benefit from an immediate tax deduction or other more
                        complex tax benefits, depending on how the insurance is
                        given.
                      </p>
                    </div>
                  </div>
                  <div className="real-group">
                    <div
                      className="real"
                      onClick={() => toggleShowReal(!showReal)}
                    >
                      <p>Real Estate</p>
                    </div>
                    <div
                      className={`real-description ${
                        showReal ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Gifts of real estate can include a personal residence, a
                        vacation home, a farm or ranch, income-producing rental
                        property or undeveloped land. Depending on the value of
                        the real estate, the donor may be able to realize a
                        charitable tax deduction and also avoid capital gains
                        taxes.
                      </p>
                    </div>
                  </div>
                  <div className="retained-group">
                    <div
                      className="retained"
                      onClick={() => toggleShowRetained(!showRetained)}
                    >
                      <p>Retained Life Estate</p>
                    </div>
                    <div
                      className={`retained-description ${
                        showRetained ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        An individual may donate real estate to Hoban while
                        retaining the full use and enjoyment of the property.
                        Hoban assumes possession of the property at the end of
                        the donor’s or the surviving beneficiary’s lifetime. The
                        donor may benefit from an immediate income tax deduction
                        as well as future estate tax savings.
                      </p>
                    </div>
                  </div>
                  <div className="charitable-group">
                    <div
                      className="charitable"
                      onClick={() => toggleShowCharitable(!showCharitable)}
                    >
                      <p>Charitable Lead Trusts</p>
                    </div>
                    <div
                      className={`charitable-description ${
                        showCharitable ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Lead trusts are usually created for a term of years
                        within which Hoban receives income. At the end of the
                        trust period, the principal is passed on to the donor’s
                        heirs.
                      </p>
                    </div>
                  </div>
                  <div className="bequest-group">
                    <div
                      className="bequest"
                      onClick={() => toggleShowBequest(!showBequest)}
                    >
                      <p>Bequests</p>
                    </div>
                    <div
                      className={`bequest-description ${
                        showBequest ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        A bequest through a will is the most common form of
                        planned giving. This is an attractive option for donors
                        who wish to retain control of their assets during their
                        lifetime and assist Hoban at a future date. Bequests may
                        designate a specific dollar amount, a percentage or the
                        residual of an estate after taxes, expenses and family
                        needs are met. Important estate tax savings may be
                        realized through this type of gift.
                        <br />
                        <br />
                        Bequest Tool: 
                        <a
                          href="http://www.freewill.com/hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          www.freewill.com/hoban
                        </a>
                        <br />
                        Beneficiaries Organizer Tool: 
                        <a
                          href="http://www.freewill.com/beneficiaries/hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          www.freewill.com/beneficiaries/hoban
                        </a>
                        <br />
                        Existing Bequest Reporting Form: 
                        <a
                          href="http://www.freewill.com/record/hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          www.freewill.com/record/hoban
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="retirement-group">
                    <div
                      className="retirement"
                      onClick={() => toggleShowRetirement(!showRetirement)}
                    >
                      <p>Retirement Plan &amp; IRA Designations</p>
                    </div>
                    <div
                      className={`retirement-description  ${
                        showRetirement ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        A donor may designate Hoban to receive any remaining
                        assets from an IRA or other qualified pension or profit
                        sharing plan. The donor maintains access to funds
                        throughout his/her lifetime, and later gifts to the
                        school are deducted from their taxable estate. This may
                        provide considerable tax savings to the donor’s heirs.
                      </p>
                    </div>
                  </div>
                  <div className="crypto-group">
                    <div
                      className="crypto"
                      onClick={() => toggleShowCrypto(!showCrypto)}
                    >
                      <p>Cryptocurrency</p>
                    </div>
                    <div
                      className={`crypto-description ${
                        showCrypto ? "" : "collapsed"
                      }`}
                    >
                      <p>
                        Donating crypto directly to Hoban allows you to avoid
                        paying capital gains taxes while claiming a tax
                        deduction.{" "}
                        <a
                          href="https://www.cryptoforcharity.io/archbishop-hoban"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Learn more and give.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </Column>
          </Columns>
        </div>
      </PageSection>
    </div>
  );
};

export default DonateNow;
