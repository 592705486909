import React, { useEffect, useState } from "react";
import { Column, Columns } from "../components/columns";
import { Link } from "react-router-dom";
// @ts-ignore
import { HashLink } from "react-router-hash-link";
import PageSection from "../components/pageContent";
import Header from "../components/header";
import rightArrow from "../images/icons/right-arrow.svg";
import introLeftShield from "../images/home/home_intro_left_shield.svg";
import introRightShield from "../images/home/home_intro_right_shield.svg";
import featuredCapitalImprovements from "../images/home/home_featured_capital_approvements_bg.png";
import featuredEndowmwnt from "../images/home/home_featured_endowment_bg.png";
import hobanPresident from "../images/hoban-president.png";
import presiderntSignature from "../images/president-signature.svg";
import hobanSeal from "../images/hoban-seal.svg";
import snapshotBackground from "../images/home/home_snapshot_bg.jpg";
import quotationIcon from "../images/icons/quotation-icon.svg";
import goldAnchorIcon from "../images/icons/gold-anchor.svg";
import shieldSlider1 from "../images/home/shield-slider/home-shield-slider-1.png";
import shieldSlider2 from "../images/home/shield-slider/home-shield-slider-2.png";
import shieldSlider3 from "../images/home/shield-slider/home-shield-slider-3.png";
import shieldSlider4 from "../images/home/shield-slider/home-shield-slider-4.png";
import shieldSlider5 from "../images/home/shield-slider/home-shield-slider-5.png";
import shieldSlider6 from "../images/home/shield-slider/home-shield-slider-6.png";
import shieldSlider7 from "../images/home/shield-slider/home-shield-slider-7.png";
import goldDome from "../images/gold_dome.svg";
import homeSnapReplacement from "../images/home/home_snap_replacement.jpg";
import capitalMobileBg from "../images/home/mobile-capital-bg.png";
import endowmentMobileBg from "../images/home/mobile-endowment-bg.png";
import carousel1 from "../images/home/image-carousel/home-carousel-1.jpg";
import carousel2 from "../images/home/image-carousel/home-carousel-2.jpg";
import carousel3 from "../images/home/image-carousel/home-carousel-3.jpg";
//@ts-ignore
import testVid from "../images/home/hoban_home.mp4";
//@ts-ignore
import Fade from "react-reveal/Fade";
import { getHomePage } from "../cms/utilities";
import { Bear } from "../components/bearCarousel";

const Home = () => {
  let isDesktop = window.innerWidth >= 1024;

  interface HomePageContent {
    currentAmountRaised: string;
    metric1Name: string;
    metric1Value: string;
    metric2Name: string;
    metric2Value: string;
    metric3Name: string;
    metric3Value: string;
    metric4Name: string;
    metric4Value: string;
  }

  const [homeContent, setHomeContent] = useState<HomePageContent>({
    currentAmountRaised: "",
    metric1Name: "",
    metric1Value: "",
    metric2Name: "",
    metric2Value: "",
    metric3Name: "",
    metric3Value: "",
    metric4Name: "",
    metric4Value: "",
  });

  //@ts-ignore
  useEffect(async () => {
    const home = await getHomePage();

    let content: HomePageContent = {
      currentAmountRaised: home.acf.current_amount_raised,
      metric1Name: home.acf.metric_1_name,
      metric1Value: home.acf.metric_1_value,
      metric2Name: home.acf.metric_2_name,
      metric2Value: home.acf.metric_2_value,
      metric3Name: home.acf.metric_3_name,
      metric3Value: home.acf.metric_3_value,
      metric4Name: home.acf.metric_4_name,
      metric4Value: home.acf.metric_4_value,
    };

    setHomeContent(content);
  }, []);

  return (
    <div className="container home">
      {!isDesktop && <Header logoColor="blue" />}
      <div className="hero">
        <div className="hero-content">
          {isDesktop && <Header logoColor="white" />}
          {isDesktop && (
            <a
              href="https://www.hoban.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <p className="archbishop-text">Archbishop Hoban High School</p>
            </a>
          )}
          {isDesktop && (
            <Fade bottom>
              <h1 className="title">
                <span className="first-line">Move Forward</span>
                <br />
                <span className="second-line">With Courage &amp;</span>
                <br />
                <span className="third-line">A Burning Desire.</span>
              </h1>
            </Fade>
          )}
        </div>
        <div className="bg-animation-container">
          <div className="bg-container">
            <video autoPlay loop playsInline muted>
              <source src={testVid} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      {!isDesktop && (
        <PageSection className="mobile-title">
          <Fade bottom>
            <h1 className="title">
              <span className="first-line">Move Forward</span>
              <br />
              <span className="second-line">With Courage &amp;</span>
              <br />
              <span className="third-line">A Burning Desire.</span>
            </h1>
          </Fade>
        </PageSection>
      )}
      <PageSection className="home-section_intro">
        <div className="intro-text-section">
          {isDesktop && (
            <img src={introLeftShield} className="left-shield" alt="" />
          )}
          <Fade bottom>
            <div className="right-border-block">
              <h2>
                A vision that will guide Hoban confidently into the future.
              </h2>
              <p>
                In his first circular letter to the Brothers of Saint Joseph,
                Blessed Basil Moreau wrote, “Receive all that I have written to
                you as the outpouring of a heart that burns with the desire to
                help you.”
              </p>
              <p>
                Living out the vision of the founder of the Congregation of Holy
                Cross, Hoban moves forward with a burning desire. Moreau’s words
                motivate us to do all we can to uphold the legacy of the
                Brothers of Holy Cross to make God known, loved and served.
              </p>
            </div>
            <Link to="/about" className="cta-button">
              <span>About The Campaign</span>
              <img src={rightArrow} />
            </Link>
          </Fade>
          {isDesktop && (
            <img src={introRightShield} className="right-shield" alt="" />
          )}
        </div>
      </PageSection>
      <PageSection className="home-section_featured">
        <div
          className="featured-update"
          style={
            isDesktop
              ? { backgroundImage: `url(${featuredCapitalImprovements})` }
              : { backgroundImage: `url(${capitalMobileBg})` }
          }
        >
          <p className="callout">
            <span className="light">Goal:&nbsp;</span>
            <span className="white">
              <sup>$</sup>11.5 million
            </span>
          </p>
          <div className="featured-details">
            <Fade bottom>
              <div className="description">
                <h2>Capital Improvements</h2>
                <p>
                  Renovate and create new learning and social spaces for
                  students to explore, learn, grow spiritually and enjoy time
                  with friends.
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <HashLink to="/about#capital" className="cta-button">
                <span>Learn More</span>
                <img src={rightArrow} alt="" />
              </HashLink>
            </Fade>
          </div>
        </div>
        <div
          className="featured-update"
          style={
            isDesktop
              ? { backgroundImage: `url(${featuredEndowmwnt})` }
              : { backgroundImage: `url(${endowmentMobileBg})` }
          }
        >
          <p className="callout">
            <span className="light">Goal:&nbsp;</span>
            <span className="white">
              <sup>$</sup>2.5 million
            </span>
          </p>
          <div className="featured-details">
            <Fade bottom>
              <div className="description">
                <h2>Endowment</h2>
                <p>
                  Increasing and diversifying the endowment to create long-term
                  sustainability, provide greater access, and preserve
                  facilities.
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <HashLink to="/about#endowment" className="cta-button">
                <span>Learn More</span>
                <img src={rightArrow} />
              </HashLink>
            </Fade>
          </div>
        </div>
      </PageSection>
      <PageSection className="home-section_pillars">
        <Columns className="pillars-columns">
          <Column className="left">
            <p>Campaign Pillars</p>
            {isDesktop && <img src={goldDome} alt="" />}
          </Column>
          <Column className="right">
            <div className="right-border-block">
              <div className="pillar">
                <h2>Achievement</h2>
                <p>
                  Our Knights must be prepared to collaborate, innovate and
                  adapt. By improving the learning environment, we continue to
                  live out the original Holy Cross mission of educating hearts
                  and minds.
                </p>
              </div>
              <div className="pillar">
                <h2>Community &amp; Wellbeing</h2>
                <p>
                  Hoban’s mission provides a world-class education that is
                  balanced with wellness and a strong sense of community. We
                  will never lose focus on the power of authentic relationships.
                </p>
              </div>
              <div className="pillar">
                <h2>Access</h2>
                <p>
                  The Hoban Trust Fund offers extraordinary educational
                  opportunities to students of all backgrounds. Hoban will
                  expand opportunities for those who desire a Hoban education,
                  substantially increasing the endowment and creating access for
                  all.
                </p>
              </div>
            </div>
          </Column>
        </Columns>
      </PageSection>
      <PageSection className="home-section_image-carousel">
        <Bear
          image1Url={carousel1}
          image2Url={carousel2}
          image3Url={carousel3}
        />
        {/* <ImageCarousel
          images={[
            { image: image1, caption: "Student Center" },
            { image: image2, caption: "Student Center" },
            { image: image3, caption: "Renovated Classroom" },
            { image: image4, caption: "Renovated Classroom" },
          ]}
        /> */}
      </PageSection>
      <PageSection className="home-section_letter">
        {isDesktop && (
          <p className="vertical-title">A Letter From our President</p>
        )}
        <div className="letter-columns">
          <Fade bottom>
            <div className="letter-text">
              <p>Dear Hoban Family,</p>
              <p>
                Blessed Basil Moreau, founder of the Congregation of Holy Cross,
                consistently challenged members of his community to “move
                forward with courage and a burning desire." Moreau believed
                deeply that the central purpose of education was to create
                communities of hope where students could explore their passions
                and become servants of God. As I walk the halls of Archbishop
                Hoban High School, I truly believe we are a living testament to
                Moreau’s compelling vision.
              </p>
              <p>
                Despite the tremendous challenges over the past two years, Hoban
                continues to rise, to thrive, to succeed. We are poised to
                advance our mission, and in doing so, we advance students.
              </p>
              <p>
                It is with great sincerity, conviction and excitement that we
                formally launch Every Knight, Every Day, the largest and most
                ambitious capital campaign in Hoban history. The campaign offers
                an intimate portrait of our future – a vision that will guide
                the Hoban family confidently in the 21st century. Actualizing
                this vision will launch Hoban to new heights and an unimaginably
                powerful future.
              </p>
              <div className="block-quote">
                <h2 className="text">
                  “Prudence is the virtue that helps us decide the best way of
                  reaching our goals and that helps us work against obstacles
                  standing in the way of reaching them. To understand the
                  necessity of prudence, we only have to reflect on our purpose
                  as Christian educators. We cannot compromise our mission or
                  hinder its progress by acting imprudently in directing our
                  schools.”
                </h2>
                <p className="author">—Blessed Basil Moreau</p>
              </div>
              <p>
                We cannot grasp this unique vision without your generous help
                and support. I urge you to walk with us on this journey and to
                “move forward with a burning desire.” We are eternally grateful
                for you, our Hoban family.
              </p>
              <p>
                <b>True to you,</b>
              </p>
              <img className="signature" src={presiderntSignature} alt="" />
            </div>
          </Fade>
          <div className="president">
            <Fade bottom>
              <>
                <img src={hobanPresident} alt="" />
                <div className="president-info">
                  <p className="name">Chris DiMauro ’99</p>
                  <div className="job">
                    <p className="title">President</p>
                    <p className="school">Archbishop Hoban High School</p>
                  </div>
                </div>
              </>
            </Fade>
            {isDesktop && <img className="seal" src={hobanSeal} alt="" />}
          </div>
        </div>
      </PageSection>
      <PageSection className="home-section_message">
        <div
          className="message-bg"
          style={{ backgroundImage: `url(${homeSnapReplacement})` }}
        >
          <Fade bottom>
            <h1 className="message">Disciples with hope to bring.</h1>
          </Fade>
        </div>
      </PageSection>
      {/* <PageSection className="home-section_snapshot">
        <div
          className="snapshot-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${snapshotBackground})` }
              : { background: "#003057" }
          }
        >
          <div className="campaign-snapshot">
            <div className="amount-container">
              <div className="snapshot-header">
                <p>Campaign Update</p>
                {isDesktop && (
                  <Fade bottom>
                    <Link to="/donate" className="donate-button">
                      <span>Donate</span>
                      <span>Now</span>
                    </Link>
                  </Fade>
                )}
              </div>
              <Fade bottom>
                <p className="current-amount">
                  <sup>$</sup>
                  {homeContent.currentAmountRaised}
                </p>
              </Fade>
            </div>
            <Fade bottom>
              <div className="campaign-stats">
                <p className="stat">
                  <span className="value">{homeContent.metric1Value}</span>
                  <span className="name">
                    &nbsp;&nbsp;{homeContent.metric1Name}
                  </span>
                </p>
                <p className="stat">
                  <span className="value">{homeContent.metric2Value}</span>
                  <span className="name">
                    &nbsp;&nbsp;{homeContent.metric2Name}
                  </span>
                </p>
                <p className="stat">
                  <span className="value">{homeContent.metric3Value}</span>
                  <span className="name">
                    &nbsp;&nbsp;{homeContent.metric3Name}
                  </span>
                </p>
                <p className="stat">
                  <span className="value">{homeContent.metric4Value}</span>
                  <span className="name">
                    &nbsp;&nbsp;{homeContent.metric4Name}
                  </span>
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </PageSection> */}
      {isDesktop && (
        <PageSection className="home-section_tagline-slider">
          <div className="slide-on-scroll">
            <Fade bottom>
              <h1 className="slider-text">Educating Hearts &amp; Minds</h1>
            </Fade>
          </div>
          {/* <SlideOnScroll />
        <div className="slidee-box">
          <h1 className='slidee'>Educating Hearts &amp; Minds</h1>
        </div> */}
        </PageSection>
      )}
      <PageSection className="home-section_numbers-testimonials">
        <div className="numbers">
          <p className="numbers-title">Hoban Students By The Numbers</p>
          <div className="numbers-row">
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  98<sup>%</sup>
                </span>
                <span className="description">
                  of graduates attend college or serve our country
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  <sup>$</sup>25M+
                </span>
                <span className="description">
                  in college scholarships earned by the Class of 2022
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  73<sup>%</sup>
                </span>
                <span className="description">
                  of all student-athletes were on the honor roll in 2020-21
                </span>
              </div>
            </Fade>
          </div>
          {/* <div className="numbers-row middle">
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  90<sup>%</sup>
                </span>
                <span className="description">
                  of students participate in athletics
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">20k</span>
                <span className="description">
                  Hours of Christian service completed annually by students
                </span>
              </div>
            </Fade>
          </div> */}
          <div className="numbers-row">
            {/* <Fade bottom>
              <div className="stat">
                <span className="numerical">52</span>
                <span className="description">
                  of graduates attend college or serve our country
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  23<sup>%</sup>
                </span>
                <span className="description">
                  in college scholarships earned by the Class of 2022
                </span>
              </div>
            </Fade> */}
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  90<sup>%</sup>
                </span>
                <span className="description">
                  of students participate in athletics
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">20k</span>
                <span className="description">
                  Hours of Christian service completed annually by students
                </span>
              </div>
            </Fade>
            <Fade bottom>
              <div className="stat">
                <span className="numerical">
                  80<sup>%</sup>
                </span>
                <span className="description">
                  of students receive financial assistance
                </span>
              </div>
            </Fade>
          </div>
        </div>
        <div className="testimonials">
          <div className="testimonial-block">
            <Fade bottom>
              <div className="quote">
                <img src={quotationIcon} className="quotation-mark" alt="" />
                <p>
                  As an alumna, Holy Cross educator and Hoban parent, it is
                  encouraging to be a part of a school that is committed to
                  excellence in all areas of instruction and engagement. The
                  redesigned classrooms and student center are part of the
                  school's overall commitment to excellence. My children and I
                  have been blessed to be a part of the Hoban Family.
                </p>
              </div>
              <div className="author">
                <span className="name">MARY (DUFFY ‘83) BULGRIN&nbsp;</span>
                <span className="about">Religion Teacher</span>
              </div>
            </Fade>
          </div>
        </div>
        {/* <div className="link-container">
          <Fade bottom>
            <Link to="/campaign-updates" className="cta-button">
              <span>Read More Donor Voices</span>
              <img src={rightArrow} alt="" />
            </Link>
          </Fade>
        </div> */}
      </PageSection>
      <PageSection className="home-section_final-section">
        <div className="cta-container">
          <img src={goldAnchorIcon} className="anchor-icon" alt="" />
          <Fade bottom>
            <p className="final-blurb">
              Join us in honoring the legacy of the Brothers of Holy Cross and
              strengthen the Hoban experience of Every Knight. Every day.
            </p>
          </Fade>
          <Fade bottom>
            <Link to="/donate" className="cta-button darker">
              <span>Donate Now</span>
              <img src={rightArrow} alt="" />
            </Link>
          </Fade>
        </div>
        <div className="image-slider-container">
          <div className="image-slider">
            <img src={shieldSlider1} className="shield" alt="" />
            <img src={shieldSlider2} className="shield" alt="" />
            <img src={shieldSlider3} className="shield" alt="" />
            <img src={shieldSlider4} className="shield" alt="" />
            <img src={shieldSlider5} className="shield" alt="" />
            <img src={shieldSlider6} className="shield" alt="" />
            <img src={shieldSlider7} className="shield" alt="" />

            <img src={shieldSlider1} className="shield" alt="" />
            <img src={shieldSlider2} className="shield" alt="" />
            <img src={shieldSlider3} className="shield" alt="" />
            <img src={shieldSlider4} className="shield" alt="" />
            <img src={shieldSlider5} className="shield" alt="" />
            <img src={shieldSlider6} className="shield" alt="" />
            <img src={shieldSlider7} className="shield" alt="" />
          </div>
        </div>
      </PageSection>
    </div>
  );
};

export default Home;
