import React from "react";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import { Column, Columns } from "../components/columns";
//@ts-ignore
import Fade from "react-reveal/Fade";
import aboutHeroBg from "../images/about/about_hero_bg.jpg";
import aboutMessage from "../images/about/about_intro.jpg";
import aboutCapital from "../images/about/about_capital_improvements.jpg";
import objective1 from "../images/about/objective_1.jpg";
import classroomRenoBg from "../images/about/classroom-reno_bg.jpg";
import classroom from "../images/about/classroom.jpg";
import aboutMessageBg from "../images/about/about-message_bg.jpg";
import { Link } from "react-router-dom";
// @ts-ignore
import { HashLink } from "react-router-hash-link";
import rightArrow from "../images/icons/right-arrow.svg";
import studentCenterBg from "../images/about/student-center_bg.jpg";
import studentCenter1 from "../images/about/student-center-1.jpg";
import studentCenter2 from "../images/about/student-center-2.jpg";
import campusDesign from "../images/about/campus-design.jpg";
import studentCenterShield from "../images/about/student-center-shield.png";
import objective2 from "../images/about/objective_2.jpg";
import wrapupBg from "../images/about/about_wrapup_bg.jpg";
import mobileWrapup from "../images/about/about_wrapup-mobile.jpg";
import mobileHeaderBg from "../images/about/about-header.jpg";

const About = () => {
  let isDesktop = window.innerWidth >= 1024;

  return (
    <div className="container about">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="about-section_hero">
        <div
          className="hero-bg"
          style={{ backgroundImage: `url(${aboutHeroBg})` }}
        >
          {isDesktop && <Header logoColor="white" />}
          <Fade bottom>
            <h1 className="heading">Every Knight. Every Day.</h1>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_intro">
        <div
          className="about-message"
          style={isDesktop ? { backgroundImage: `url(${aboutMessage})` } : {}}
        >
          <Columns>
            <Column className="left">
              <Fade bottom>
                <div className="blurb">
                  <h2>
                    We invite you to explore the priorities of the campaign and
                    prayerfully consider how you can make an impact on the lives
                    of Every Knight, Every Day.
                  </h2>
                </div>
                <div className="pillars">
                  <p className="pillars-title">Campaign Pillars</p>
                  <div className="pillars-types">
                    <HashLink to="/about#achievement" className="pillar-link">
                      <p>Achievement</p>
                    </HashLink>
                    <HashLink to="/about#community" className="pillar-link">
                      <p>Community &amp; Wellbeing</p>
                    </HashLink>
                    <HashLink to="/about#access" className="pillar-link">
                      <p>Access</p>
                    </HashLink>
                  </div>
                </div>
              </Fade>
            </Column>
            <Column className="right">
              <Fade bottom>
                <p>
                  The Every Knight, Every Day capital campaign enhances
                  achievement, strengthens community and improves access to a
                  Hoban education.
                </p>
                <p>
                  Your gift will enhance the academic experience by providing
                  new educational spaces equipped with the tools that inspire
                  student achievement and a community-learning environment. Our
                  mission-driven teachers will have the resources that support
                  mobility, adaptability and connectivity. And, while we equip
                  our students with the latest technology, we will never lose
                  focus on the power of authentic relationships.
                </p>
                <p>
                  Living out the vision of the founder of the Congregation of
                  Holy Cross, Hoban moves forward with a burning desire.
                  Moreau’s words motivate us to do all we can to uphold the
                  legacy of the Brothers of Holy Cross to make God known, loved
                  and served.
                </p>
              </Fade>
            </Column>
          </Columns>
        </div>
      </PageSection>
      <PageSection className="about-section_capital-improvements">
        <div
          id="capital"
          className="capital-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${aboutCapital})` }
              : { backgroundColor: "#fefdf5" }
          }
        >
          <Fade bottom>
            <div className="capital-content">
              <h1>
                Capital
                <br />
                Improvements
              </h1>
            </div>
          </Fade>
          <Fade bottom>
            <p>
              <span className="goal">Goal:&nbsp;</span>
              <span className="amount">
                <sup>$</sup>11.5 million
              </span>
            </p>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_objective-1">
        <div
          className="objective-1-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${objective1})` }
              : { backgroundColor: "#003057" }
          }
        >
          <Fade bottom>
            <div className="obj-1-blurb">
              <p>
                Over the last decade, Hoban has prioritized updating academic
                spaces to provide students and Holy Cross educators with the
                best learning and teaching environments possible. New areas such
                as the Asente and Scala Families Innovation Center, McCool
                Center for Entrepreneurial and Leadership Studies and CREATE
                Studios are all shining examples of what can be accomplished
                when learning spaces are designed to complement the educational
                process.
              </p>
              <p>
                Now’s the time to renovate Hoban’s core classrooms and create
                new learning and social spaces that accommodate creativity,
                inspire innovation and enhance relationship-building.
              </p>
            </div>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_classroom-reno">
        <div
          className="reno-content"
          style={
            isDesktop ? { backgroundImage: `url(${classroomRenoBg})` } : {}
          }
        >
          <Columns>
            <Column className="left">
              <Fade bottom>
                <div id="achievement">
                  <p className="reno-subhead top-border">Achievement</p>
                  <h1 className="reno-title">Classroom Renovation</h1>
                  <p className="blurb">
                    Today's classrooms must be multifaceted to teach students
                    how to be adaptable, collaborative, mobile and agile. Hoban
                    will renovate 35 classrooms that span all academic
                    departments.
                  </p>
                  <div className="updates">
                    <p className="reno-subhead">Updates Include</p>
                    <div className="reno-types">
                      <p>
                        21<sup>st</sup> Century Furniture
                      </p>
                      <p>Modern Paint Updates</p>
                      <p>New Carpeting</p>
                      <p>Improved Educational Technology</p>
                      <p>LED Lighting</p>
                      <p>Air Conditioning</p>
                    </div>
                  </div>
                  <p className="learn-more">
                    Learn how to to name a classroom in honor of your family,
                    favorite teacher or Brother of Holy Cross.
                  </p>
                  <Fade bottom>
                    <Link to="/donate" className="cta-button">
                      <span>Make a Donation</span>
                      <img src={rightArrow} />
                    </Link>
                  </Fade>
                </div>
              </Fade>
            </Column>
            <Column className="right">
              <Fade bottom>
                <div className="reno-media">
                  <img src={classroom} />
                  <iframe
                    width="100%"
                    height={isDesktop ? "600px" : "200px"}
                    src="https://www.youtube.com/embed/iaU2SOGwexs"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </Fade>
            </Column>
          </Columns>
        </div>
      </PageSection>
      <PageSection className="about-section_message">
        <div
          className="message-bg"
          style={{ backgroundImage: `url(${aboutMessageBg})` }}
        >
          <Fade bottom>
            <h1 className="message">Every Knight. Every Day.</h1>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_student-center">
        <div
          className="student-center-bg"
          style={
            isDesktop ? { backgroundImage: `url(${studentCenterBg})` } : {}
          }
        >
          <Columns>
            <Column className="left">
              <Fade bottom>
                <div className="reno-media">
                  <img src={studentCenter1} />
                  <img src={studentCenter2} />
                </div>
              </Fade>
            </Column>
            <Column className="right">
              <Fade bottom>
                <div id="community" className="comunity-container">
                  <p className="reno-subhead top-border">
                    Community &amp; Wellbeing
                  </p>
                  <h1 className="reno-title">Student Center</h1>
                  <p className="blurb">
                    Hoban provides a world-class education that is balanced with
                    wellness and a strong sense of community. As such, a new,
                    state-of-the-art student center with flexible functionality
                    and universal application will be constructed. The
                    two-story, 8,500-square-foot center will house an updated
                    cafeteria and kitchen, gathering spaces and new classrooms.
                    Located on the east side of the building, near the Hoban
                    tennis courts, the addition will allow for the eventual
                    renovation of Foley Commons and office space to maximize the
                    school’s existing square footage.
                  </p>
                  <p className="learn-more">
                    Learn more about student center naming opportunities.
                  </p>
                  <Fade bottom>
                    <Link to="/contact" className="cta-button">
                      <span>Contact Us</span>
                      <img src={rightArrow} />
                    </Link>
                  </Fade>
                </div>
              </Fade>
            </Column>
          </Columns>
        </div>
        <div
          className="campus-design-bg"
          style={{ backgroundImage: `url(${campusDesign})` }}
        ></div>
        <div className="shield-section">
          <Columns>
            <Column>
              <Fade bottom>
                <img src={studentCenterShield} />
              </Fade>
            </Column>
            <Column>
              <Fade bottom>
                <p>
                  By contributing to capital improvements, you are helping to
                  create flexible academic spaces, a beautiful new student
                  center, an updated cafeteria and more. Together, we will build
                  and renovate spaces where our students and community can
                  explore, learn, grow spiritually, collaborate and enjoy
                  friends.
                </p>
                <div className="circle-container">
                  <Link to="/donate" className="donate-button">
                    <span>Donate</span>
                    <span>Now</span>
                  </Link>
                </div>
              </Fade>
            </Column>
          </Columns>
        </div>
      </PageSection>
      <PageSection className="about-section_endowment-growth">
        <div
          id="endowment"
          className="endowment-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${aboutCapital})` }
              : { backgroundColor: "#fefdf5" }
          }
        >
          <Fade bottom>
            <div className="endowment-content">
              <h1>
                Endowment
                <br />
                Growth
              </h1>
            </div>
          </Fade>
          <Fade bottom>
            <p>
              <span className="goal">Goal:&nbsp;</span>
              <span className="amount">
                <sup>$</sup>2.5 million
              </span>
            </p>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_objective-2">
        <div
          id="access"
          className="objective-2-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${objective2})` }
              : { backgroundColor: "#c89211" }
          }
        >
          <Fade bottom>
            <div className="obj-2-blurb">
              <p>
                As a Holy Cross school, inclusiveness is one of the values on
                which Hoban was built. Through philanthropic support, the
                endowment fund through the Hoban Trust Fund offers extraordinary
                educational opportunities to students of all backgrounds who
                otherwise could not attend Hoban. The Every Knight, Every Day
                campaign will also diversify the endowment to maintain school
                facilities. The endowment principal earns investment income to
                provide student scholarships and support the school. A healthy
                endowment fund secures Hoban’s future success and enriches our
                entire school community.
              </p>
            </div>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="about-section_financial-aid">
        <Fade bottom>
          <iframe
            width="100%"
            height={isDesktop ? "600px" : "200px"}
            src="https://www.youtube.com/embed/VrKVnWFbf_U"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Fade>
        <Columns>
          <Column className="left">
            <Fade bottom>
              <div className="reno-media">
                <p>
                  As part of the Every Knight, Every Day capital campaign, Hoban
                  will expand opportunities for those who desire a Hoban
                  education. Substantially increasing our strong endowment will
                  continue to create long-term sustainability and greater access
                  for all.
                </p>
                <p>
                  Gifts of any amount may be contributed to either student
                  financial assistance or the newly created facilities
                  endowment. Your contribution will be invested and generate an
                  annual distribution to support Hoban and our students.
                </p>
              </div>
            </Fade>
          </Column>
          <Column className="right">
            <Fade bottom>
              <p className="fin-subhead">Access</p>
              <h1 className="fin-title">Student Financial Assistance</h1>
              <p className="blurb">
                <b>Scholarships</b>
                <br />
                Provide merit or need-based financial tuition assistance for
                students.
                <br />
                <br />
                <b>Facilities</b>
                <br />
                Supports the upkeep and maintenance of Hoban’s facilities and
                campus.
              </p>
            </Fade>
          </Column>
        </Columns>
      </PageSection>
      {/* {isDesktop && (
        <PageSection className="about-section_wrapup">
          <div
            className="wrapup-bg"
            style={{ backgroundImage: `url(${wrapupBg})` }}
          >
            <Fade bottom>
              <p className="title">True to You</p>
              <p>Join us in strengthening the Hoban experience.</p>
              <Fade bottom>
                <Link to="/donate" className="cta-button">
                  <span>Donate Now</span>
                  <img src={rightArrow} />
                </Link>
              </Fade>
            </Fade>
          </div>
        </PageSection>
      )}
      {!isDesktop && (
        <PageSection className="about-section_wrapup">
          <div className="wrapup-bg">
            <Fade bottom>
              <img src={mobileWrapup} />
              <div className="words-section">
                <p className="title">True to You</p>
                <p>Join us in strengthening the Hoban experience.</p>
                <Fade bottom>
                  <Link to="/donate" className="cta-button">
                    <span>Donate Now</span>
                    <img src={rightArrow} />
                  </Link>
                </Fade>
              </div>
            </Fade>
          </div>
        </PageSection>
      )} */}
      <PageSection className="about-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to You</p>
            <p>
              Join us in strengthening the {!isDesktop && <br />} Hoban
              experience.
            </p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default About;
